@font-face {
  font-display: swap;
  font-family: "Mark W01 Heavy";
  font-weight: 800;
  src: url("assets/fonts/6215393/fbb4168c-3acc-4115-9a34-593ef03c9b07.woff2") format("woff2"),
  url("assets/fonts/6215393/7f34d676-21e3-4ac7-998d-04f8428dff18.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Mark W01 Bold";
  font-weight: 700;
  src: url("assets/fonts/6460613/da4f22ef-a1b6-401a-8a40-56954ce5141d.woff2") format("woff2"),
  url("assets/fonts/6460613/c061ab4d-74ab-4c1c-9399-152de03f973d.woff") format("woff");
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/*
  Overridden in an attempt to fix https://minoxsoftware.atlassian.net/browse/HUB-329?focusedCommentId=68438,
  as suggested by https://stackoverflow.com/a/70048720/2596974
*/
.h-screen {
  height: 100vh;  /* older browsers */
  height: 100dvh; /* newer browsers */
}

@layer base {
  body {
    @apply text-gray-800 dark:text-white antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @apply font-mark-bold font-medium text-gray-800 dark:text-white break-words;
  }

  h1,
  .h1 {
    @apply font-mark-heavy font-extrabold;
    @apply text-3xl leading-tight mb-5;
    @apply md:text-5xl md:leading-tight md:mb-7;
  }

  h2,
  .h2 {
    @apply font-mark-heavy font-extrabold;
    @apply text-2xl mb-3;
    @apply md:text-4xl md:mb-5;
  }

  h3,
  .h3 {
    @apply font-mark-heavy font-extrabold;
    @apply text-xl mb-2;
    @apply md:text-2xl md:mb-3;
  }

  h4,
  .h4 {
    @apply text-lg mb-1;
    @apply md:text-xl md:mb-2;
  }

  h5,
  .h5 {
    @apply text-base mb-0;
    @apply md:text-lg md:mb-1;
  }

  h6,
  .h6 {
    @apply text-base mb-0;
    @apply md:text-base md:mb-0;
  }

  p {
    @apply mb-6 last:mb-0 leading-custom;
  }

  ul,
  ol {
    @apply list-disc mb-6  last:mb-0;
  }

  ol {
    @apply list-decimal;
  }

  li {
    @apply ml-6 pl-2 leading-custom;
  }

  p a,
  table a,
  li a {
    @apply text-blue-500 hover:text-blue-600 hover:underline;
  }

  a {
    @apply transition-colors duration-200;
  }

  app-story blockquote,
  app-stories blockquote,
  app-combipackagedetails blockquote {
    @apply h1 md:text-4xl xl:text-5xl my-8 md:my-12 last:mb-0;
  }

  app-blog-post blockquote {
    @apply border-l-4 my-8 pl-5;
  }

  blockquote h4 {
    color: rgb(96, 113, 128);
  }

  cite {
    @apply block not-italic font-mark-heavy text-blue-500 text-lg md:text-xl my-2 md:my-4;
  }

  th {
    @apply text-gray-800 font-bold text-left;
    @apply px-2 pb-4 flex-none;
  }

  td {
    @apply px-2 py-4 flex-none;
  }

  iframe {
    @apply aspect-video w-full h-auto mb-6 rounded-3xl my-8 md:my-12 last:mb-0;
  }

  input[type="text"]:not(.my-input),
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    @apply rounded border-gray-200 px-5 py-3 outline-none focus:ring-0 focus:border-gray-500;
  }

  select {
    @apply font-semibold text-gray-800;
  }

  app-blog-post main img,
  app-story main img {
    @apply w-full h-auto my-8 md:my-12 rounded-3xl;
  }
}

@layer components {
  .card {
    @apply bg-white shadow-soft p-8 xl:p-10 xl:pb-12 rounded-3xl flex flex-col;
  }
}

@layer utilities {

  .border-top {
    @apply border-gray-75 dark:border-gray-600 border-t-[1px];
  }

  .border-bottom {
    @apply border-gray-75 dark:border-gray-600 border-b-[1px];
  }

  /* default colour for older api's which no longer have one.. otherwise the app is white on the dashboard */
  .bg-gradient-to-r {
    background-color: #ccc;
  }

  .inline-api-icon {
    @apply m-0 w-4 h-4 mb-1 inline;
  }

  .inline-api-icon-dropdown {
    @apply m-0 w-4 h-4 mt-1 inline;
  }

  .inline-api-icon-large {
    @apply m-0 w-8 h-8 mb-2 inline;
  }

  .package-api-icon {
    @apply m-0 p-3.5 inline;
  }

  .center {
    @apply flex justify-center items-center;
  }

  .my-section {
    @apply mt-10 md:mt-15;
    @apply mb-15 md:mb-20;
  }

  .my-section a:not(.my-button) {
    @apply underline text-blue-500 hover:text-blue-600 cursor-pointer;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .fixed-header-space {
    @apply lg:pt-30 lg:-mt-30;
  }

  /** (old) combidesk styles **/
  .text-readonly {
    @apply text-gray-500 bg-yellow-100 p-2 rounded-lg border-yellow-100 border-2;
  }

  .step-description-upsell {
    @apply text-gray-400 text-xs;
  }

  .btn {
    @apply uppercase duration-200 text-sm font-medium px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none hover:scale-105 focus:scale-100;
  }

  .btn-link {
    @apply uppercase text-sm font-medium text-center px-4 py-2 outline-none focus:outline-none;
  }

  .btn-icon {
    @apply w-9 h-9 rounded-full text-gray-600 hover:shadow hover:bg-gray-100 focus:outline-none;
  }

  .btn-icon-red {
    @apply hover:text-combidesk-red hover:bg-red-100;
  }

  .btn-red {
    @apply border-combidesk-red bg-combidesk-red hover:shadow-combidesk-red/40 focus:shadow-combidesk-red/20 text-white hover:scale-105 focus:scale-100;
  }

  .btn-blue {
    @apply border-combidesk-blue bg-combidesk-blue hover:shadow-combidesk-blue/40 focus:shadow-combidesk-blue/20 text-white hover:scale-105 focus:scale-100;
  }

  .btn-black {
    @apply border-gray-800 bg-gray-800 py-3 hover:shadow-gray-800/40 focus:shadow-gray-800/20 text-white hover:scale-[1.01] focus:scale-100;
  }

  .form-label {
    @apply block text-gray-600 text-xs mt-6 mb-2;
  }

  .form-input {
    @apply transition-colors duration-300 p-3 placeholder-gray-400 text-gray-600 bg-gray-25 rounded text-sm shadow focus:bg-white w-full outline-none;
  }

  .fadeInOnEnter {
    @apply animate-fadeIn opacity-0;
  }

  .fadeInOnEnterQuickly {
    @apply animate-fadeInQuickly opacity-0;
  }

  .fadeOutOnExitQuickly {
    @apply animate-fadeOutQuickly;
  }

  .delay-500 {
    animation-delay: .5s;
  }

  .delay-1000 {
    animation-delay: 1s;
  }

  .delay-1500 {
    animation-delay: 1.5s;
  }

  .delay-2000 {
    animation-delay: 2s;
  }

  .api-icon-large {
    background-position: center;
    background-size: 32px;
    background-repeat: no-repeat;
  }

  .api-icon-small {
    background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
  }

  .tooltip .tooltip-text {
    @apply transition-all duration-300 opacity-0 px-2 py-0.5 absolute z-30 inline-block text-xxs rounded text-white bg-gray-400 font-light font-sans;
  }

  .tooltip:hover .tooltip-text {
    @apply opacity-80;
  }

  .dropdown-menu-item {
    @apply shadow bg-white hover:text-gray-800 py-2 px-4 block whitespace-nowrap focus:outline-none;
  }

  .dropdown-grid-item {
    @apply shadow bg-white hover:text-gray-800 py-1 px-4 block whitespace-nowrap focus:outline-none;
  }

  .step .support-header {
    @apply text-red-500 font-bold;
  }

  .text-seagreen {
    color: seagreen;
  }

  .shadow-dropdown {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 4px rgba(0, 0, 0, .18);
  }

  // add an 'external link' icon to all external links
  a[target="_blank"]:not(.suppress-icon)::after,
  a[target="_new"]:not(.suppress-icon)::after {
    content: "";
    width: 12px;
    height: 12px;
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(33 150 243)' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
  }

  div.chat-with-us::before {
    content: "";
    width: 12px;
    height: 12px;
    margin-left: -17px;
    float: left;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADeUlEQVR4nO2Z6YtPURjHP2PsJctYBpNdwiu7LEmihKQkexgm/AEKL2xvLOWFV5YRSWEw9uUNMST7jBjCNFJqjH3fM3rqO3W7c3/3d+c3c+feNJ86dTvnuc95zn6e50A99cSWpkBrJfuOLQ2BMcAq4BhQDHwHKlzpm8rygZXAaCA9SsOHAbnAG5ehX4EHwEXgnNJF5X1zyb4GdgFD6tLwCcANhxHlwE5gJtAtwP/dgVky/JVDzzVgXJiGdwXOqrK/wBlgUg2nQTowWaNU2ZDTQBdqmTnAZ1VwKaQhHwYUqI5PGtEakwZskVJrQLbywiINyAG+qM5NNamvAbBbip4A/ag7+qvOCq0Vs6XabJUC2z06Uve0BwodI1EtFujHx0AG0dEOKJEt84L+1EOLyFJvoqeP1t/HgNs0J9ViW7BxIUc22SnuyygJXg95t6kuacBN2TbCTzBfQqGeiCkyXrYdTiTQAfgD3I9Z71diNj0EfgNt8dl5VhNf1vjtSHtUOJj4MlQ22gFbhbvAL6Ax8aWJptAtr8I3OjSSYQ3cobWywkduAHBFyb4TsUK6tgfsvFL5EVWw3r8TQMFil1OSyLhbDhnbAr0Y4NJlupNxR7am3IDlrkrtGuzFPYdMUQKZ4S5dpjsZtzWNqlCu4UlGc+AoUAZs9pEzn/eRkn0nYrN0HZHuZJRoulehUM54nBdxY7+ZkqthHER8GSgb93oVZqvQb2eJmlWycZFXYYaGxxZfXCnSdceuPZ4cVwv9Fl1UjJZtdt1PelRfJn4UBL0pn5DgfOLDXNl0PohwFvBByaJoUdMNeK+QpbmXgZit6NujiJ36loqKWO8vq+7P6x33GE8HImRaKV5aoctjSh7QNkd4pS4jFN21ZVrdeQrhp8xaTScLbSwkfGYAb2V8bm29IUxxKA1rd+rrCOn8SGXOJ2ODlE+vZb0jgQM6YU3/VTWm1jmmCnp5lHUCxuo9LMjinKio91OHL1CszgklItJC+3CpRwVZcgkrDXkOXNC1ZD+wT7Eccy1faD05383y9eoTaihnoSpc58q3Xn+pMjP4FPDM44GvMpXpmmK9PzWgA1NjGjge6DIdp2Oe47lpo6sHG+ns6Kkp1xloRkQskaGH9NRkQ/5TeXbdmEaMyQTeeUyF7wqDRPHwEZg0vURWKDZvp/FBYCnQJriaev5v/gHRLBH5hAYtygAAAABJRU5ErkJggg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 4px;
    transform: scaleX(-1); /* flips the image horizontally */
  }

  /** a placeholder for the 'hover' style below, so we don't get a layout shift **/
  div.chat-with-us::after {
    content: ".";
    @apply text-white;
  }

  div.chat-with-us:hover::after {
    content: attr(data-content);
    text-decoration: none;
    @apply text-red-500;
  }

  // but don't add the 'external link' icon to buttons and social icons
  button a::after,
  socialicons a::after {
    display: none !important;
  }

  .step .support-header-green {
    @apply font-bold text-seagreen;
  }

  .ck-file-dialog-button {
    /* disable image upload of ckeditor */
    pointer-events: none;
  }

  .ck-content figure.image img {
    /* make images scale similar to our email template (that's the only place we use the wysiwyg editor) */
    width: inherit !important;
  }

  .bg-dropdown {
    @apply bg-white;
  }
}

.webshop-beginnen-table {
  @apply table-auto border-collapse border border-gray-200;
}
.webshop-beginnen-table th {
  @apply border border-gray-200;
}
.webshop-beginnen-table td {
  @apply border border-gray-200;
}
