@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik Regular'), local('Rubik-Regular'), url('assets/fonts/bizcuit/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('assets/fonts/bizcuit/Rubik-Medium.ttf') format('truetype');
}

.bizcuit {
  * {
    font-family: Rubik, sans-serif;
  }

  .h4 {
    font-size: 28px;
  }

  a.suppress-icon {
    text-decoration: underline;
  }

  bizcuitbutton {
    @apply my-2 block sm:inline-block sm:w-auto w-full;
  }

  .bizcuit-button-with-icons {
    @apply mt-6 bg-white text-gray-900 flex flex-row items-center rounded-2xl shadow-md shadow-gray-500/25 font-mark-bold font-medium w-full text-[17px];
  }

  .bg-dropdown {
    background-color: rgb(249 247 244);
  }

  .shadow-dropdown {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px rgba(0, 0, 0, .1);
  }
}

.bizcuit.dark {
  .dark\:bg-dropdown-dark {
    background-color: rgb(43 41 39);
  }

  .dark\:bg-dropdown-inline-header-dark {
    color: rgb(97 95 90);
  }

  .dark\:bg-bizcuit-dark {
    background-color: rgb(31 31 31);
  }
}

